import React, { useState,useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate

const TaskDetail = () => {
  const [selectedTab, setSelectedTab] = useState('taskDetail'); // Default active tab is 'taskDetail'
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [years, setYears] = useState([]);
  const [selectedYearId, setSelectedYearId] = useState(1);
  console.log("Yearid ........=====>",selectedYearId)


  const navigate = useNavigate(); // Initialize useNavigate hook
  const location =useLocation();
  const {adminid,firstname}=location.state||{}
  console.log(adminid,"skfskdjfhksjdhfkjs:")

  const handleSubmit = async (e) => {
    e.preventDefault();

    const apiUrl = `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/assignment`;
    const token = 'a6039607dfd014db1c2ff40d25f14c4a715f0282';
    const jwt =
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4MTU0MDg5LCJpYXQiOjE2OTgxNTM3ODksImp0aSI6ImQ1ZTg2NDUwNWQ0NjRlODk4ZGRiZmI2ZDkwMTZjNDZhIiwidXNlcl9pZCI6NX0.YGNlNvfZtrbJjYBxjsEAQ8S5lzqC9XtrV9S6hAkgLrw';

    // Create URL-encoded payload
    const payload = new URLSearchParams();
    payload.append('title', title);
    payload.append('description', description);
    payload.append('adminId', adminid);
    payload.append('yearId', selectedYearId); 

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
          jwt: jwt,
        },
        body: payload.toString(),
      });

      if (!response.ok) {
        throw new Error(`Failed with status ${response.status}: ${response.statusText}`);
      }

      const data = await response.json();
      console.log('API Response:', data);

      // Extract the ID from the response
      const assignmentId = data.data.id;

      // Notify the user that the task was saved successfully
      alert('Task saved successfully!');

      // Route to the 'assignTo' page with the assignment ID
      navigate('/assignTo', { state: { id: assignmentId ,title,description,adminid,yearID:selectedYearId,firstname} });  // Pass the ID via the state property
    } catch (err) {
      console.error('Error:', err);
      alert('Failed to save task: ' + err.message);
    }
  };
  const fetchYears = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/allyearsAdmin`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
            "Content-Type": "application/json",
            jwt: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4MTU0MDg5LCJpYXQiOjE2OTgxNTM3ODksImp0aSI6ImQ1ZTg2NDUwNWQ0NjRlODk4ZGRiZmI2ZDkwMTZjNDZhIiwidXNlcl9pZCI6NX0.YGNlNvfZtrbJjYBxjsEAQ8S5lzqC9XtrV9S6hAkgLrw",
          },
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch data");
      }
      const result = await response.json();
      setYears(result.data);

      console.log(years);
    } catch (err) {
      setError(err.message);
      console.error("Error fetching data:", err);
    }
  };
  const handleYearChange = (event) => {
    const newYearId = Number(event.target.value);
    if (newYearId !== selectedYearId) {
      setSelectedYearId(newYearId);
      // localStorage.setItem(`selectedYear_${id}`, newYearId);
    }
  };
  useEffect(() => {
    fetchYears();
  }, []);

  return (
    <div className="pt-[1.5rem] pr-[7.5rem] pb-[1.5rem] pl-[7.5rem]  mx-auto min-h-screen bg-[#FAF4E5]">
                  <img className='logo-stuudent' src={`${process.env.REACT_APP_BASE_URL}media/adminimage/Frame1141.png`}  alt="Logo" />

      <h1 className="text-3xl ml-[50%] mt-[50px] font-bold mb-4">Assignment</h1>

      {/* Tab Navigation */}
      <div className="flex space-x-4 bg-[#000f5c] text-white rounded-lg p-4 mb-6">
        <p
          className={`px-4 py-2 rounded-lg font-semibold bg-[#ffcd47] text-black`}
        >
          Task Details
        </p>
        <p
          className={`px-4 py-2 rounded-lg font-semibold `}
        >
          Assign to
        </p>
        <p
          className={`px-4 py-2 rounded-lg font-semibold `}
        >
          Select Question
        </p>
        <p
          className={`px-4 py-2 rounded-lg font-semibold`}
        >
          Assign Marks
        </p>
        <p
          className={`px-4 py-2 rounded-lg font-semibold `}
        >
          Assign Timeline
        </p>
        <p
          className={`px-4 py-2 rounded-lg font-semibold `}
        >
          Publish & Assign
        </p>
      </div>

      {/* Render Tab Content Based on Selected Tab */}
      {selectedTab === 'taskDetail' && (
        <div className="task-detail p-4 bg-light-yellow rounded-lg bg-[white]">
                                  <label className="block font-semibold mb-2">Student Years</label>

                        <td className="bg-white text-black rounded-r-lg px-2 py-2">

                  {years.length > 0 ? (
                    <select
                      className="border-button-bg text-button-bg border-[1px] px-[10px] py-[3px] rounded-md"
                      onChange={handleYearChange}
                      value={selectedYearId || ""}
                    >
                      {years.map((year) => (
                        <option key={year.pk} value={year.pk}>
                          {year.fields.year}
                        </option>
                      ))}
                    </select>
                  ) : (
                    "No records yet"
                  )}
                </td>

          <form onSubmit={handleSubmit}>

            <div className="mb-4">
              <label className="block font-semibold mb-2">Title</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Add the worksheet title"
                className="border border-gray-300 rounded-lg p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block font-semibold mb-2">Description</label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Add description of the worksheet"
                className="border border-gray-300 rounded-lg p-2 w-full"
              />
            </div>
            {error && <p className="text-red-500">{error}</p>}
            <button
              type="submit"
              className="bg-blue-600 text-white py-2 px-4 rounded-lg"
              disabled={loading}
            >
              {loading ? 'Saving...' : 'Save & Next'}
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default TaskDetail;
