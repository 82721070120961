import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import "./DashBrd.css";
import Sidebar from "./Sidebar";

const Lessons = () => {
  const [lessons, setLessons] = useState([]);
  const [selectedLessons, setSelectedLessons] = useState(new Set());
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const lessonsPerPage = 8; // Number of lessons to display per page
  const navigate = useNavigate();
  const location = useLocation();
  const {yearId, yearName } = location.state || {}; // Get the yearId and yearName from the state
  const [trigger, setTrigger] = useState(false);
  const { firstname } = location.state || {};
  // console.log("yearID:LJkjdflskjdflkjfl",yearId)
  console.log("selected lesson",lessons)

  const handleItemQuestion = (id, lesson) => {
    console.log("iddsldfjlskjdflskdf",id)
    navigate("/question", {
      state: { yearId: yearId, LessonId: id, LessonName: lesson },
    });
  };

  const fetchLessons = async () => {
    console.log("yearid", yearId, "lksdfjlks", yearName);
    try {
      const item = { YearId: yearId };
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/lessionOfYearAdmin`,
        {
          method: "POST",
          headers: {
            Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
            "Content-Type": "application/json",
            jwt: "YOUR_JWT_TOKEN",
          },
          body: JSON.stringify(item),
        }
      );
      const result = await response.json();
      setLessons(result.data || []);
    } catch (err) {
      console.error("Error fetching lessons:", err);
      setError("Failed to fetch lessons.");
    }
  };

  useEffect(() => {
    fetchLessons();
  }, [yearId,trigger]);
  
  const handleToggleLessonSelection = async (lessonId, isSelect) => {
    try {
      const headers = {
        Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
        "Content-Type": "application/json",
        jwt: "",
      };

      if (isSelect === "1") {
        // Unselect lesson
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/deleteLesson`,
          { Lessonid: lessonId },
          { headers }
        );
        setSelectedLessons((prevSelected) => {
          const newSelected = new Set(prevSelected);
          newSelected.delete(lessonId);
          return newSelected;
        });
      } else {
        // Select lesson
        const lessonList = Array.from(selectedLessons).map((id) => ({
          YearId: yearId,
          LessonId: id,
        }));
        lessonList.push({ YearId: yearId, LessonId: lessonId });

        await axios.post(
          `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/selectedlesson`,
          { Lessonlist: lessonList },
          { headers }
        );
        setSelectedLessons((prevSelected) => {
          const newSelected = new Set(prevSelected);
          newSelected.add(lessonId);
          return newSelected;
        });
      }
    } catch (error) {
      console.error("Error toggling lesson selection:", error);
      setError("Failed to toggle lesson selection.");
    }
    setTrigger(prev => !prev); 
  };

  // Calculate the current lessons to display
  const indexOfLastLesson = currentPage * lessonsPerPage;
  const indexOfFirstLesson = indexOfLastLesson - lessonsPerPage;
  const currentLessons = lessons.slice(indexOfFirstLesson, indexOfLastLesson);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(lessons.length / lessonsPerPage);
  const handleInsightRedirect = () => {
    navigate("/insight");
    console.log(lessons)
  };
  return (
    <div className="parent bg-[]">
      <div className="dashboard-container">
        <div className="sidebar" style={{ padding: "8px" }}>
          <Sidebar
           handleInsightRedirect={handleInsightRedirect}
          />
        </div>
      </div>
      <div className="parent-main vertical">
        <h1 className="user-name">{firstname}</h1>
        <div className="lessondashboard">
          <h1 className="headings">{yearName}</h1>
          <div className="lesson vertical">
            {currentLessons.map((item) => (
              <div className="vertical" key={item.pk}>
                <div className="lesson-item">
                  <h2 className="lesson-named">
                    {/* <p className="w-[20%]">{item.fields.lession}</p> */}
                    <p className="w-[20%]">
                    {item.fields.lession.replace(/Testlesson(\d+-\d+)/, 'Test Lesson $1')}

                    </p>
                    <button
                      className={` w-[100px] text-white px-4 py-2 rounded ${
                        item.fields.isSelect === "1"
                          ? "bg-green-500 hover:bg-green-600"
                          : "bg-red-500 hover:bg-red-600"
                      }`}
                      onClick={() =>
                        handleToggleLessonSelection(item.pk, item.fields.isSelect)
                      }
                    >
                      {item.fields.isSelect === "1" ? "Disable" : "Select"}
                    </button>
                    <p
                      className="viewdetails"
                      onClick={() =>
                        handleItemQuestion(item.pk, item.fields.lession)
                      }
                    >
                      View Details <svg xmlns="http://www.w3.org/2000/svg" width="7" height="11" viewBox="0 0 7 11" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.350946 0.210453C0.221273 0.345074 0.148438 0.527559 0.148438 0.717823C0.148438 0.908088 0.221273 1.09057 0.350946 1.22519L4.47702 5.50433L0.350023 9.78348C0.223948 9.91895 0.154235 10.1003 0.155897 10.2886C0.15756 10.4768 0.230466 10.6569 0.358913 10.7899C0.487359 10.923 0.661069 10.9984 0.842628 11C1.02419 11.0015 1.19907 10.9291 1.32961 10.7982L5.94593 6.0117C6.0756 5.87708 6.14844 5.6946 6.14844 5.50433C6.14844 5.31407 6.0756 5.13159 5.94593 4.99696L1.32961 0.210453C1.2653 0.143734 1.18895 0.0908072 1.10491 0.0546967C1.02087 0.0185862 0.930786 0 0.839815 0C0.748844 0 0.658765 0.0185862 0.574724 0.0546967C0.490683 0.0908072 0.414329 0.143734 0.350023 0.210453H0.350946Z" fill="#08059B"/>
                      </svg>
                    </p>
                  </h2>
                </div>
                <div className="seperation"></div>
              </div>
            ))}
          </div>

          {/* Pagination Controls */}
          <div className="pagination">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => paginate(index + 1)}
                className={`page-button ${currentPage === index + 1 ? "active" : ""}`}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lessons;
