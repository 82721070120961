import './App.css';
import Login from './login';
import Signup from './signup';
import Dashboard from './dashboard';
import Question from './question';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Years from './years';
import Insight from './Insight';
import StudentDetails from './StudentDetails';
import StudentReport from './StudentReport';
import AssignTime from './AssignTime';
import Generate from './Generate';
import RequestTime from './RequestTime';
import Lessons from './Lessons';
import Assignment from './Assignment';
import AddAssignment from './AddAssignment';
import FileUpload from './FileUpload';
import TaskDetail from './TaskDetail';
import AssignTo from './AssignTo';
import QuestionSelection from './QuestionSelection';
import AssignMarks from './AssignMarks';
import AssignTimeline from './AssignTimeline';
import Publish from './Publish';
import Worksheet from './components/Worksheet';
import Library from './components/Library';
import AssignmentDetailReport from './components/AssignmentDetailReport';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route index element={<Login/>}/>
      <Route path="/login" element={<Login/>}/>
      <Route  path="/signup"element={<Signup/>}/>
      <Route  path="/dashboard"element={<Dashboard/>}/>
      <Route  path="/question"element={<Question/>}/>
      <Route  path="/insight"element={<Insight/>}/>
      <Route  path="/generate"element={<Generate/>}/>
      <Route  path="/assign"element={<AssignTime/>}/>
      <Route path="/student/:id" element={<StudentDetails />} />
      <Route path="/student/:id/report/:lessonId" element={<StudentReport />} /> 
      <Route path="/requesttime/:userid" element={<RequestTime/>}/>
      <Route path="/lessons" element={<Lessons />} />
      <Route path='/assignment' element={<Assignment/>}/>
      <Route path='/assignment/addAssignment' element={<AddAssignment/>}/>
      <Route path='/fileUpload' element={<FileUpload/>}/>
      <Route path='/assignment/titleandDesc' element={<TaskDetail/>}/>
      <Route path='/assignTo' element={<AssignTo/>}/>
      <Route path='/selectQuestion' element={<QuestionSelection/>}/>
      <Route path='/assignMarks' element={<AssignMarks/>}/>
      <Route path='/assignTimeline' element={<AssignTimeline/>}/>
      <Route path='/publish' element={<Publish/>}/>
      <Route path='/worksheet' element={<Worksheet/>}/>
      <Route path='/library' element={<Library/>}/>
      <Route path='/assignmentReport' element={<AssignmentDetailReport/>}/>

    </Routes>
    </BrowserRouter>
  );
}

export default App;
