import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const AssignTime = () => {
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]); // For search functionality
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [timeInputs, setTimeInputs] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate(); // State to hold search input
  const location = useLocation();
  const { adminid } = location.state || {};
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleSubmit = () => {
    saveTime();
    alert("Default time set successfully")

    closeModal();
  };


  const saveTime=async()=>{


    try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/updateAssignTime`,
          {
            method: "POST",
            headers: {
              Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
              "Content-Type": "application/json",
              jwt: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI4OTkyMjE1LCJpYXQiOjE3Mjg5OTE5MTUsImp0aSI6IjczMGYzMDIxNGQxMzQ2OWJiYmNlNTRkMjU5ZTRjMTI1IiwidXNlcl9pZCI6Ik5vbmUifQ.-_y3cDpKqaFaPkuHBTlMlpk-iEOMsLQKpzV_j3ymyGA",
            },
            body: JSON.stringify({ adminId:adminid,startTime,endTime }),
          }
        );
  
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
  
        const result = await response.json();
        console.log(result,"df");

      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
  }

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/studentList`,
        {
          method: "POST",
          headers: {
            Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
            "Content-Type": "application/json",
            jwt: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI4OTkyMjE1LCJpYXQiOjE3Mjg5OTE5MTUsImp0aSI6IjczMGYzMDIxNGQxMzQ2OWJiYmNlNTRkMjU5ZTRjMTI1IiwidXNlcl9pZCI6Ik5vbmUifQ.-_y3cDpKqaFaPkuHBTlMlpk-iEOMsLQKpzV_j3ymyGA",
          },
          body: JSON.stringify({ Adminid: "65" }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const result = await response.json();
      console.log(result.data);
      setStudents(
        result.data.filter((item) => item.fields.schoolName === "Test School") ||
          []
      );
      setFilteredStudents(
        result.data.filter((item) => item.fields.schoolName === "Test School") ||
          []
      ); // Initialize filtered students
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const defaultTime = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/updateAssignTime`,
        {
          method: "POST",
          headers: {
            Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
            "Content-Type": "application/json",
            jwt: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI4OTkyMjE1LCJpYXQiOjE3Mjg5OTE5MTUsImp0aSI6IjczMGYzMDIxNGQxMzQ2OWJiYmNlNTRkMjU5ZTRjMTI1IiwidXNlcl9pZCI6Ik5vbmUifQ.-_y3cDpKqaFaPkuHBTlMlpk-iEOMsLQKpzV_j3ymyGA",
          },
          body: JSON.stringify({ Adminid: adminid, startTime, endTime }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const result = await response.json();
      console.log(result.data);
      setStudents(
        result.data.filter((item) => item.fields.schoolName === "Haileybury") ||
          []
      );
      setFilteredStudents(
        result.data.filter((item) => item.fields.schoolName === "Haileybury") ||
          []
      ); // Initialize filtered students
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleTimeChange = (studentId, timeType, value) => {
    setTimeInputs((prevInputs) => ({
      ...prevInputs,
      [studentId]: {
        ...prevInputs[studentId],
        [timeType]: value,
      },
    }));
  };
  const handleAssignTime = async (studentId) => {
    const { from, to } = timeInputs[studentId] || {};

    if (!from || !to) {
      alert("Please enter valid time values for all fields.");
      return;
    }

    try {
      // First API Call: Assign Time
      const assignTimeResponse = await fetch(
        `${process.env.REACT_APP_BASE_URL}apiadmin/updatesignup`,
        {
          method: "POST",
          headers: {
            Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
            "Content-Type": "application/json",
            jwt: filteredStudents.find((item) => item.pk === Number(studentId))
              .fields.jwtoken,
          },
          body: JSON.stringify({
            id: studentId,
            appStartTime: from,
            appEndTime: to,
          }),
        }
      );

      if (!assignTimeResponse.ok) {
        throw new Error("Failed to assign time");
      }

      const assignResult = await assignTimeResponse.json();
      console.log("assingnResult", assignResult);
      alert("Time assigned successfully for student ID: " + studentId);

      // Second API Call: Send Notification to Student
      const notificationResponse = await fetch(
        `${process.env.REACT_APP_BASE_URL}apiadmin/sendNotificationToStudent`,
        {
          method: "POST",
          headers: {
            Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
            "Content-Type": "application/json",
            jwt: filteredStudents.find((item) => item.pk === Number(studentId))
              .fields.jwtoken,
          },
          body: JSON.stringify({
            StudentId: studentId.toString(),
            StartTime: from,
            EndTime: to,
          }),
        }
      );

      if (!notificationResponse.ok) {
        throw new Error("Failed to send notification");
      }

      const notificationResult = await notificationResponse.json();
      console.log("sdfs", notificationResult);
      alert("Notification sent successfully to student ID: " + studentId);
    } catch (error) {
      alert("Error: " + error.message);
    }
  };

  

  const convertTo12HourFormat = (time) => {
    const [hours, minutes] = time.split(":"); // We don't need seconds now
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0); // Set seconds to 0 as we're not showing them
    
    const options = { hour: '2-digit', minute: '2-digit', hour12: true }; // Remove seconds
    return date.toLocaleTimeString([], options);
  };
  

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    // Filter students based on the search query (name or email)
    const filtered = students.filter(
      (student) =>
        student.fields.user_name.toLowerCase().includes(query) ||
        student.fields.email.toLowerCase().includes(query)
    );
    setFilteredStudents(filtered);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const toREquestTime = function () {
    navigate("/requesttime");
  };

//   console.log(students);
  return (
    <div className="min-h-screen pt-[1.6rem] bg-[#FEF4DB] p-4">
      <img className='logo-stuudent' src={`${process.env.REACT_APP_BASE_URL}media/adminimage/Frame1141.png`} alt="Logo" />
      <h1 className="text-3xl mb-4 items-center ml-[45%]  mt-[50px]">Assign Time</h1>
      <div className='flex justify-between mb-[30px]'>
        <input
          type="text"
          placeholder="Search by name or email"
          value={searchQuery}
          onChange={handleSearchChange}
          className="border border-gray-300 p-2 rounded mb-4 w-[20%] text-sm"
        />
        <button
          onClick={openModal}
          className="bg-blue-700 text-white px-4 rounded h-10 text-sm"

        >
          Add Default Time
        </button>
      </div>

      {filteredStudents.length > 0 ? (
        <table className="table-auto border-collapse border bg-white border-gray-300 text-xs w-full">
        <thead>
          <tr className="bg-gray-200">
            <th className="border border-gray-300 p-1">Name</th>
            {/* <th className="border border-gray-300 p-1">Email</th> */}
            <th className="border border-gray-300 p-1">Year</th>
            <th className="border border-gray-300 p-1">Default (hh:mm)</th>
            <th className="border border-gray-300 p-1">From (hh:mm)</th>
            <th className="border border-gray-300 p-1">To (hh:mm)</th>
            <th className="border border-gray-300 p-1">Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredStudents.map((student) => (
            <tr key={student.pk} className="text-center">
              <td className="border border-gray-300 p-1">{student.fields.user_name}</td>
              {/* <td className="border border-gray-300 p-1">{student.fields.email}</td> */}
              <td className="border border-gray-300 p-1">{student.fields.year}</td>
              <td className="border border-gray-300 p-1">
  {`${convertTo12HourFormat(student.fields.appStartTime)} - ${convertTo12HourFormat(student.fields.appEndTime)}`}
</td>

              <td className="border border-gray-300 p-1">
                <input
                  type="time"
                  value={timeInputs[student.pk]?.from || ""}
                  onChange={(e) => handleTimeChange(student.pk, "from", e.target.value)}
                  className="border border-gray-300 p-1 rounded w-full text-center"
                />
              </td>
              <td className="border border-gray-300 p-1">
                <input
                  type="time"
                  value={timeInputs[student.pk]?.to || ""}
                  onChange={(e) => handleTimeChange(student.pk, "to", e.target.value)}
                  className="border border-gray-300 p-1 rounded w-full text-center"
                />
              </td>
              <td className="border border-gray-300 p-1">
                <button
                  onClick={() => handleAssignTime(student.pk)}
                  className="bg-blue-500 text-white p-1 rounded hover:bg-blue-600 text-xs mx-auto"
                >
                  Assign Time
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      
      ) : (
        <p>No students found</p>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg w-96">
            <h2 className="text-xl mb-4">Set Default Time</h2>
            <div className="mb-4">
              <label htmlFor="startTime" className="block text-sm">
                Start Time
              </label>
              <input
                type="time"
                id="startTime"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                className="border border-gray-300 p-2 rounded w-full"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="endTime" className="block text-sm">
                End Time
              </label>
              <input
                type="time"
                id="endTime"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                className="border border-gray-300 p-2 rounded w-full"
              />
            </div>
            <div className="flex justify-end space-x-2">
              <button
                onClick={closeModal}
                className="bg-gray-400 text-white p-2 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                className="bg-blue-500 text-white p-2 rounded"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssignTime;
