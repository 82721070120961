import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Assignment = () => {
  const [assignmentList, setAssignmentList] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { adminid,firstname } = location.state || {};
  console.log("adminid", adminid);
  const handleOnclick = (id) => {
    navigate("/worksheet", { state: { id } });
  };
  console.log("first")

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}apiadmin/mathadmin/listAssignmentadmin`,
        {
          method: "POST",
          headers: {
            Authorization: "Token a6039607dfd014db1c2ff40d25f14c4a715f0282",
            "Content-Type": "application/json",
            jwt: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4MTU0MDg5LCJpYXQiOjE2OTgxNTM3ODksImp0aSI6ImQ1ZTg2NDUwNWQ0NjRlODk4ZGRiZmI2ZDkwMTZjNDZhIiwidXNlcl9pZCI6NX0.YGNlNvfZtrbJjYBxjsEAQ8S5lzqC9XtrV9S6hAkgLrw",
          },
          body: JSON.stringify({ adminID: adminid }),
        }
      );
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch data");
      }
  
      const jsonData = await response.json();
      console.log("Assignments data:", jsonData);
  
      // Reverse the list to display the latest one on top
      setAssignmentList(jsonData.data.reverse()); // Reversing the assignment list before setting the state
  
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };
  

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="p-4 bg-[#FEF4DB] min-h-screen">
      <img
        className="logo-stuudent"
        src={`${process.env.REACT_APP_BASE_URL}media/adminimage/Frame1141.png`} 
        alt="Logo"
      />

      <h1 className="text-3xl mt-[40px] ml-[40%] font-bold mb-6">Assignment</h1>

      {/* Top Buttons */}
      <div className="flex justify-end gap-4 mb-6">
        <button
          className="bg-blue-700 text-white px-4 py-2 rounded"
          onClick={() => navigate("./titleandDesc", { state: { adminid ,firstname} })}
        >
          + Add new assignment
        </button>
      </div>

      {/* Table */}
      <div
        className="border rounded-lg shadow-sm"
        style={{ "border-style": "none" }}
      >
        <table className="table-auto border-collapse bg-[white] border border-gray-300 text-sm w-full">
  <thead>
    <tr className="bg-gray-200 text-center">
      <th className="border border-gray-300 p-2 text-gray-600 font-semibold">Date</th>
      <th className="border border-gray-300 p-2 text-gray-600 font-semibold">Title</th>
      <th className="border border-gray-300 p-2 text-gray-600 font-semibold">Description</th>
      <th className="border border-gray-300 p-2 text-gray-600 font-semibold">Timeline</th>
      <th className="border border-gray-300 p-2 text-gray-600 font-semibold">Action</th>
    </tr>
  </thead>
  <tbody>
    {assignmentList && assignmentList.length > 0 ? (
      assignmentList.map((assignment, index) => {
        const startTime = new Date(assignment.fields.startTime);
        const endTime = new Date(assignment.fields.endTime);

        const dateOptions = {
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        const timeOptions = {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        };

        return (
          <tr key={index} className="text-center">
            <td className="border border-gray-300 p-2">
              {assignment.fields.created_date
                .substring(0, 10)
                .split("-")
                .reverse()
                .join("-")}
            </td>
            <td className="border border-gray-300 p-2">
              {assignment.fields.title}
            </td>
            <td className="border border-gray-300 p-2">
              {assignment.fields.description}
            </td>
            <td className="border border-gray-300 p-2">
              {new Intl.DateTimeFormat("en-US", dateOptions).format(startTime)}{" "}
              {new Intl.DateTimeFormat("en-US", timeOptions).format(startTime)}{" "}
              -{" "}
              {new Intl.DateTimeFormat("en-US", timeOptions).format(endTime)}
            </td>
            <td className="border border-gray-300 p-2">
              <a
                className="text-[#08059B] font-poppins text-sm font-medium leading-none tracking-tight rounded border border-[#08059B] flex justify-center items-center gap-2 px-4 py-2 no-underline cursor-pointer transition-transform transform hover:scale-105 hover:bg-[rgba(38,24,107,0.13)] shadow hover:shadow-lg"
                onClick={() => handleOnclick(assignment.pk)}
              >
                View Details
              </a>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="5" className="px-6 py-4 text-center">
          No assignments available
        </td>
      </tr>
    )}
  </tbody>
</table>

      </div>

      {/* Pagination */}
      {/* <div className="flex justify-center mt-4">
        <button className="px-3 py-1 border rounded-l">{"<"}</button>
        <button className="px-3 py-1 border rounded-r">{">"}</button>
      </div> */}
    </div>
  );
};

export default Assignment;
